export const AUTH_ERROR = 'AUTH_ERROR'
export const GET_MODULE_LIST = 'GET_MODULE_LIST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const MODULE_LOGIN_SUCCESS = 'MODULE_LOGIN_SUCCESS'
export const MODULE_LOGOUT = 'MODULE_LOGOUT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const WHOAMI = 'WHOAMI'

export interface Privilege {
  module: string;
  area: string;
  role: string;
  permissions: string[];
}

export interface StaffModule {
  id: string;
  nama: string;
  privileges: Privilege[];
}

export interface WhoAmI extends StaffModule {
  iat: number;
  exp: number;
}

export interface ModuleLogin {
  token: string;
  test: StaffModule;
}

export interface Module {
  _id: string;
  role: string;
  area: string;
  module: string;
}

export interface WhoAmIType {
  type: typeof WHOAMI
  payload: WhoAmI
}

export interface RegisterSuccessType {
  type: typeof REGISTER_SUCCESS
  payload: boolean
}

export interface LoginSuccessType {
  type: typeof LOGIN_SUCCESS
  payload: string
}

export interface AuthErrorType {
  type: typeof AUTH_ERROR
}

export interface GetModuleType {
  type: typeof GET_MODULE_LIST
  payload: Module[]
}

export interface ModuleLoginType {
  type: typeof MODULE_LOGIN_SUCCESS
  payload: ModuleLogin
}

export interface ModuleLogoutType {
  type: typeof MODULE_LOGOUT
}

export interface LogoutSuccessType {
  type: typeof LOGOUT_SUCCESS
}

export interface AuthState {
  current_module: any
  main_auth: boolean
  main_token: null | string
  module_auth: boolean
  module_token: null | string
  module_list: Module[]
  register_status: boolean
  user: null | WhoAmI
}

export type AuthActionType =
  | AuthErrorType
  | GetModuleType
  | LoginSuccessType
  | LogoutSuccessType
  | ModuleLoginType
  | ModuleLogoutType
  | RegisterSuccessType
  | WhoAmIType