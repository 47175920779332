import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { GlobalStyles } from "../styles";

const loading = (
  <div className={"pt-3 text-center"}>
    <div className={"sk-spinner sk-spinner-pulse"}/>
  </div>
)

const MainLogin = React.lazy(() => import('./auth/MainLogin'))
const ModuleLogin = React.lazy(() => import('./auth/ModuleLogin'))
const Register = React.lazy(() => import('./auth/Register'))
const ResetPassword = React.lazy(() => import('./auth/ResetPassword'))
const VerifyAccount = React.lazy(() => import('./auth/VerifyAccount'))
const VerifyPassword = React.lazy(() => import('./auth/VerifyPassword'))

const Dashboard = React.lazy(() => import('./core/Dashboard'))

const Main: React.FC = () => {
  return (
    <div className="px-3 px-lg-5 py-4 py-lg-5" style={{ height: '100vh' }}>
      <GlobalStyles/>
      <Suspense fallback={loading}>
        <Routes>
          <Route path={"/login"} element={<MainLogin/>} />
          <Route path={"/module"} element={<ModuleLogin/>} />
          <Route path={"/register"} element={<Register/>} />
          <Route path={"/reset-password"} element={<ResetPassword/>} />
          <Route path={"/verify/:token"} element={<VerifyAccount/>} />
          <Route path={"/verify-password/:token"} element={<VerifyPassword/>} />
          
          <Route path={"/"} element={<Dashboard/>} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default Main