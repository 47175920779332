import {
  AUTH_ERROR,
  GET_MODULE_LIST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  MODULE_LOGIN_SUCCESS,
  MODULE_LOGOUT,
  REGISTER_SUCCESS,
  WHOAMI,
  AuthActionType,
  AuthState,
} from "./types";

const initialState: AuthState = {
  current_module: JSON.parse(localStorage.getItem('current_module') || '{}'),
  main_auth: !!localStorage.getItem('main_token'),
  main_token: localStorage.getItem('main_token'),
  module_auth: !!localStorage.getItem('module_token'),
  module_token: localStorage.getItem('module_token'),
  module_list: [],
  register_status: false,
  user: null
}

export default function (state = initialState, action: AuthActionType): AuthState {
  switch (action.type) {
    case WHOAMI:
      localStorage.setItem('user', JSON.stringify(action.payload))
      return {
        ...state,
        user: action.payload
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        register_status: action.payload
      }
    case LOGIN_SUCCESS:
      localStorage.setItem('main_token', action.payload)
      return {
        ...state,
        main_auth: true,
        main_token: action.payload
      }
    case GET_MODULE_LIST:
      return {
        ...state,
        module_list: action.payload
      }
    case MODULE_LOGIN_SUCCESS:
      localStorage.setItem('module_token', action.payload.token)
      localStorage.setItem('current_module', JSON.stringify(action.payload))
      return {
        ...state,
        module_auth: true,
        current_module: action.payload
      }
    case MODULE_LOGOUT:
      localStorage.removeItem('module_token')
      localStorage.removeItem('current_module')
      return {
        ...state,
        module_auth: false,
        current_module: null
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.clear()
      return {
        ...state,
        user: null,
        main_auth: false,
        main_token: null,
        module_auth: false,
        module_token: null,
        current_module: null
      }
    default:
      return state
  }
}
      